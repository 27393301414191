<script lang="ts">
  import { onMount } from "svelte";
  import Prism from "prismjs";
  import CodeBlock from "./CodeBlock.svelte";
  import PostSection from "./PostSection.svelte";
  import PostQuote from "./PostQuote.svelte";

  let postId: string;

  onMount(() => {
    postId = location.pathname.split("/").pop() || "";

    // Initialize Prism
    Prism.highlightAll();
  });
</script>

<div class="w-full">
  <article class="py-8 sm:py-12 mb-12">
    <header class="mt-6 mb-12 sm:mb-16">
      <h1 class="text-3xl sm:text-5xl font-black mb-5 leading-tight tracking-tight">
        Hello World
      </h1>
      <p class="text-sm sm:text-base text-txt-muted-light dark:text-txt-muted-dark">
        Published on Oct 29, 2024 by Daniil Koltsov
      </p>
    </header>

    <PostSection>
      <figure>
        <img
          src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmiro.medium.com%2Fmax%2F11668%2F1*T5HJv_JYlmBALYbaNlKzcA.png&f=1&nofb=1&ipt=147946389c0c442c5b98161cbf8d3eeaf116c15323b8a07dd57dc2199b0f2e06&ipo=images"
          alt="The Go Gopher"
          class="mx-auto mb-1 rounded"
        />
      </figure>
      <figcaption class="text-center text-txt-muted-light dark:text-txt-muted-dark">
        The beloved Go mascot
      </figcaption>
    </PostSection>

    <PostSection title="A Dark Introduction to Our Tale">
      <p class="text-base sm:text-lg antialiased leading-golden tracking-normal
        max-w-readable mx-auto prose-p:text-txt-body-light dark:prose-p:text-txt-body-dark
        prose-p:font-normal prose-p:mb-8">
        In the depths of silicon and circuitry, where binary whispers echo through the halls of computation, I find myself drawn to share a tale most curious - my descent into the realm of Golang. Like a wanderer in the digital wilderness, I have traversed the landscapes of many languages, but none have captured my mortal soul quite like this one.
      </p>
    </PostSection>

    <PostQuote>
      <p class="text-base sm:text-lg antialiased leading-golden tracking-normal
        max-w-readable mx-auto prose-p:text-txt-body-light dark:prose-p:text-txt-body-dark
        prose-p:font-normal prose-p:mb-8">
        In the vast expanse of programming tongues, Go stands alone - a beacon of simplicity in the gathering darkness, where efficiency blooms like winter wheat in the valley.
      </p>
    </PostQuote>

    <PostSection title="The Haunting Appeal of Golang">
      <ul class="list-disc pl-5 text-base sm:text-lg antialiased leading-golden tracking-normal
        max-w-readable mx-auto prose-p:text-txt-body-light dark:prose-p:text-txt-body-dark
        prose-p:font-normal prose-p:mb-8">
        <li>A clarity that cuts through the fog like moonlight on water</li>
        <li>Speed that races like wind through the Salinas Valley</li>
        <li>Threads that weave like destiny's own tapestry</li>
        <li>Libraries vast as ancient catacombs</li>
      </ul>
    </PostSection>

    <PostSection title="Code Example">
      <CodeBlock
        filename="main.go"
        code={`
package main

func main() {
    // Setup logger
    logger := setupLogger()
    defer logger.Sync()

    // Setup router
    router := mux.NewRouter()
    router.HandleFunc("/", HandleIndex())
    router.PathPrefix("/static/").Handler(
        http.StripPrefix("/static/",
        http.FileServer(http.Dir("./static"))))

    // Start server
    startServer(router)
}
        `}
      />
    </PostSection>

    <PostSection title="Key Takeaways">
      <ul class="list-disc pl-5 text-base sm:text-lg antialiased leading-golden tracking-normal
        max-w-readable mx-auto prose-p:text-txt-body-light dark:prose-p:text-txt-body-dark
        prose-p:font-normal prose-p:mb-8">
        <li>Golang is great for building efficient and scalable applications</li>
        <li>The community is supportive and growing</li>
        <li>Learning Go can boost your career in backend development</li>
      </ul>
    </PostSection>

    <PostQuote>
      <p class="text-base sm:text-lg antialiased leading-golden tracking-normal
        max-w-readable mx-auto prose-p:text-txt-body-light dark:prose-p:text-txt-body-dark
        prose-p:font-normal prose-p:mb-8">
        If you're interested in learning Golang, check out the official <a
          href="https://golang.org/doc/"
          class="text-accent-light dark:text-accent-dark hover:underline"
          >Go documentation</a
        > for great resources!
      </p>
    </PostQuote>

    <PostSection title="Final Thoughts">
      <p class="text-base sm:text-lg antialiased leading-golden tracking-normal
        max-w-readable mx-auto prose-p:text-txt-body-light dark:prose-p:text-txt-body-dark
        prose-p:font-normal prose-p:mb-8">
        As the digital sun sets on our discourse, I must confess that Golang has become more than mere technology - it is the very lifeblood of my craft. Like the determined spirits of the Dust Bowl, it perseveres through the most demanding tasks, never yielding to the chaos of complexity. May you, dear reader, find in its embrace the same dark beauty and relentless efficiency that has so thoroughly possessed my programming soul.
      </p>
    </PostSection>
  </article>
</div>

<style>
  /* Optimize reading width */
  article {
    max-width: 80ch;
    margin: 0 auto; /* Center the article */
    padding: 0 1rem; /* Add padding to prevent overflow */
  }

  /* Responsive image handling */
  figure img {
    border-radius: 0.5rem; /* rounded-lg */
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1); /* shadow-lg */
    height: auto;
  }

  :global(.dark) figure img {
    box-shadow: none; /* dark:shadow-none */
  }
</style>