<script lang="ts">
    import { privacyTranslations } from "./t_privacy";
    import { language, type LanguageCode } from "../../../stores/langStore";
</script>

<div
    id="privacy-container"
    class="
        container
        mx-auto
        px-4
        py-8
        max-w-3xl
    ">
    <div
        id="privacy-card"
        class="
            flex
            flex-col
            p-8
            rounded-2xl
            bg-form-light
            dark:bg-form-dark
            shadow-lg
        ">
        <h1
            id="privacy-title"
            class="
                mb-6
                text-3xl
                font-bold
                text-txt-heading-light
                dark:text-txt-heading-dark
            ">
            {privacyTranslations[$language].title}
        </h1>

        <div
            id="privacy-content"
            class="
                flex
                flex-col
                space-y-6
                text-txt-body-light
                dark:text-txt-body-dark
            ">
            <p
                id="privacy-intro"
                class="
                    leading-relaxed
                ">
                {privacyTranslations[$language].introduction}
            </p>

            <p
                id="privacy-data"
                class="
                    leading-relaxed
                ">
                {privacyTranslations[$language].noData}
            </p>

            <p
                id="privacy-contact"
                class="
                    leading-relaxed
                ">
                {privacyTranslations[$language].contact}
            </p>
        </div>
    </div>
</div>
