import { logger } from '../../utils/logger/logger';
import { authTranslations } from './t_auth';
import { language } from '../../stores/langStore';
import { get } from 'svelte/store';

// Base URL for authentication endpoints - switches between production and development environments
const AUTH_BASE_URL = import.meta.env.MODE === 'production'
  ? 'https://auth.shindo.dev'
  : '/auth';


// Add these interfaces to match the Go backend response structure
interface ErrorDetails {
  code?: string;
  details?: string;
}

interface ApiResponse {
  status: string;
  message: string;
  data?: any;
  error?: ErrorDetails;
}

// Update AuthResponse to include the new error structure
interface AuthResponse {
  message?: string;
  data?: {
    id?: string | number;
    username?: string;
    email?: string;
    level?: string;
    token_issued_at?: string;
    token_expires_at?: string;
    token_expires_in?: string;
  };
  error?: string;
  errorDetails?: ErrorDetails;
}

const debugResponse = async (response: Response, endpoint: string) => {
  logger.debug(`${endpoint} Response Headers:`, {
    headers: Object.fromEntries(response.headers.entries()),
    status: response.status,
    statusText: response.statusText,
    url: response.url
  });
};

/**
 * Registers a new user with email and password
 * @param email - User's email address
 * @param username - User's username
 * @param password - User's password
 * @returns Promise containing the API response
 */
export const registerUser = async (email: string, username: string, password: string): Promise<AuthResponse> => {
  try {
    const response = await fetch(`${AUTH_BASE_URL}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, username, password }),
      credentials: 'include',
    });

    const data: ApiResponse = await response.json();
    logger.info('Registration response:', data);

    const currentLang = get(language);

    if (!response.ok) {
      if (data.error) {
        switch (data.error.code) {
          case 'EMAIL_ALREADY_REGISTERED':
            return { error: authTranslations[currentLang].emailExists, errorDetails: data.error };
          case 'USERNAME_TAKEN':
            return { error: authTranslations[currentLang].usernameExists, errorDetails: data.error };
          case 'VALIDATION_ERROR':
            return { error: authTranslations[currentLang].invalidInput, errorDetails: data.error };
          case 'INVALID_REGISTRATION_DATA':
            return { error: authTranslations[currentLang].invalidInput, errorDetails: data.error };
          default:
            return {
              error: authTranslations[currentLang].registrationFailed,
              errorDetails: data.error
            };
        }
      }
      return { error: data.message || authTranslations[currentLang].registrationFailed };
    }

    return { data: data.data };
  } catch (error) {
    logger.error('Registration network error:', error);
    const currentLang = get(language);
    return {
      error: authTranslations[currentLang].networkError,
      errorDetails: {
        code: 'NETWORK_ERROR',
        details: error instanceof Error ? error.message : authTranslations[currentLang].unknownError
      }
    };
  }
};

/**
 * Authenticates a user with email and password
 * @param email - User's email address
 * @param password - User's password
 * @returns Promise containing the API response
 */
export const loginUser = async (
  email: string,
  password: string
): Promise<AuthResponse> => {
  logger.info('Attempting login for user:', email);

  try {
    logger.debug('Login Request Headers:', {
      'Content-Type': 'application/json',
      credentials: 'include'
    });

    const response = await fetch(`${AUTH_BASE_URL}/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ email, password })
    });

    await debugResponse(response, 'Login');

    logger.info('Login response status:', response.status);

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || `Login failed with status: ${response.status}`);
    }

    logger.info('Login successful');
    return data;
  } catch (error) {
    logger.error('Login error:', error instanceof Error ? error.message : 'Unknown error');
    return { error: error instanceof Error ? error.message : 'Failed to login' };
  }
}

/**
 * Logs out the current user
 * @returns Promise containing the API response
 */
export const logoutUser = async (): Promise<AuthResponse> => {
  logger.info('Attempting logout');

  try {
    const response = await fetch(`${AUTH_BASE_URL}/logout`, {
      method: 'POST',
      credentials: 'include'
    });

    logger.info('Logout response status:', response.status);

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      logger.error('Logout failed:', errorData);
      throw new Error(errorData.error || `Logout failed with status: ${response.status}`);
    }

    const data = await response.json();
    logger.info('Logout successful');
    return { message: data.message };
  } catch (error) {
    logger.error('Logout error:', error instanceof Error ? error.message : 'Unknown error');
    return { error: error instanceof Error ? error.message : 'Failed to logout' };
  }
};

/**
 * Checks the current authentication status
 * @returns Promise containing the API response
 */
export const checkAuthStatus = async (): Promise<AuthResponse> => {
  logger.debug('Checking auth status');

  try {
    const response = await fetch(`${AUTH_BASE_URL}/status`, {
      credentials: 'include'
    });

    logger.info('Auth status response:', response.status);

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      logger.error('Auth status check failed:', errorData);
      throw new Error(errorData.error || `Auth check failed with status: ${response.status}`);
    }

    const data = await response.json();
    logger.info('Auth status check successful');
    return {
      data: {
        username: data.username,
        email: data.email,
        level: data.level,
        token_issued_at: data.token_issued_at,
        token_expires_at: data.token_expires_at,
        token_expires_in: data.token_expires_in
      }
    };
  } catch (error) {
    logger.error('Auth status check error:', error instanceof Error ? error.message : 'Unknown error');
    return { error: error instanceof Error ? error.message : 'Failed to check auth status' };
  }
};