<script lang="ts">
  import { Router, Route } from "svelte-routing";
  import Layout from "./pages/layout/Layout.svelte";
  import Home from "./pages/home/Home.svelte";
  import Login from "./pages/auth/Login/Login.svelte";
  import Register from "./pages/auth/Register/Register.svelte";
  import Verify from "./pages/auth/Verify/Verify.svelte";
  import e404 from "./pages/errors/404/404.svelte";
  import Status from "./pages/status/Status.svelte";
  import Post from "./pages/post/Post.svelte";
  import Callback from "./pages/auth/Login/Callback.svelte";
  import Dialog from "./pages/dialog/Dialog.svelte";
  import Privacy from "./pages/info/Privacy/Privacy.svelte";
  import Terms from "./pages/info/Terms/Terms.svelte";
  import Contact from "./pages/info/Contact/Contact.svelte";
  import About from "./pages/info/About/About.svelte";
  import Shindo from "./pages/info/Shindo/Shindo.svelte";
  import Map from "./pages/map/Map.svelte";
  import ProtectedRoute from "./utils/components/ProtectedRoute.svelte";
  import Success from "./pages/auth/Success/Success.svelte";
  import SEO from "./utils/components/SEO.svelte";
</script>

<Router>
  <Layout>
    <!-- Public routes -->
    <Route path="/" component={Home} />
    <Route path="/login" component={Login} />
    <Route path="/register" component={Register} />
    <Route path="/register/verify" component={Verify} />
    <Route path="/register/success" component={Success} />
    
    <Route path="/privacy" component={Privacy} />
    <Route path="/terms" component={Terms} />
    <Route path="/about" component={About} />
    <Route path="/contact" component={Contact} />
    <Route path="/shindo" component={Shindo} />
    <Route path="/map" component={Map} />

    <!-- Protected routes -->
    <Route path="/login/callback" let:params>
      <ProtectedRoute component={Callback} />
    </Route>
    <Route path="/dialog" let:params>
      <ProtectedRoute component={Dialog} />
    </Route>
    <Route path="/status" let:params>
      <ProtectedRoute component={Status} />
    </Route>
    <Route path="/post" let:params>
      <ProtectedRoute component={Post} />
    </Route>

    <!-- Public 404 route - should be last -->
    <Route path="*" component={e404} />
  </Layout>
</Router>
