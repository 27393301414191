type LogLevel = 'debug' | 'info' | 'warn' | 'error';

interface LogMessage {
  level: LogLevel;
  message: string;
  data?: any;
  timestamp: string;
  caller?: string;
}

export const logger = {
  debug: (message: string, data?: any) => {
    const caller = new Error().stack?.split('\n')[2]?.trim() || 'unknown';
    log('debug', message, caller, data);
  },
  info: (message: string, data?: any) => {
    const caller = new Error().stack?.split('\n')[2]?.trim() || 'unknown';
    log('info', message, caller, data);
  },
  warn: (message: string, data?: any) => {
    const caller = new Error().stack?.split('\n')[2]?.trim() || 'unknown';
    log('warn', message, caller, data);
  },
  error: (message: string, data?: any) => {
    const caller = new Error().stack?.split('\n')[2]?.trim() || 'unknown';
    log('error', message, caller, data);
  }
};

function log(level: LogLevel, message: string, caller: string, data?: any) {
  const timestamp = new Date().toLocaleString('en-US', {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  });

  const filename = caller.split('/').pop()?.split(':')[0] || 'unknown';

  const logMessage: LogMessage = {
    level,
    message,
    data,
    timestamp,
    caller: filename
  };

  const logPrefix = `[${logMessage.timestamp}] [${level.toUpperCase()}] [${filename}]`;

  switch (level) {
    case 'debug':
      data ? console.debug(logPrefix, message, data) : console.debug(logPrefix, message);
      break;
    case 'info':
      data ? console.info(logPrefix, message, data) : console.info(logPrefix, message);
      break;
    case 'warn':
      data ? console.warn(logPrefix, message, data) : console.warn(logPrefix, message);
      break;
    case 'error':
      data ? console.error(logPrefix, message, data) : console.error(logPrefix, message);
      break;
  }
}
