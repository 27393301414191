<script lang="ts">
  import { slide } from "svelte/transition";
  import {
    language,
    type LanguageCode,
    type Language,
    languages,
  } from "../../../stores/langStore";

  let isLangDropdownOpen = false;

  const languageEntries = Object.entries(languages) as [
    LanguageCode,
    Language,
  ][];

  const setLanguage = (lang: LanguageCode) => {
    language.set(lang);
    isLangDropdownOpen = false;
  };
</script>

<div class="relative">
  <button
    on:click={() => (isLangDropdownOpen = !isLangDropdownOpen)}
    class="flex items-center text-txt-body-light dark:text-txt-body-dark font-medium transition-colors hover:text-txt-body-light-hover dark:hover:text-txt-body-dark-hover"
  >
    <span class="h-5 w-5 ml-2 text-xl leading-none"
      >{languages[$language].flag}</span
    >
  </button>

  {#if isLangDropdownOpen}
    <div
      transition:slide
      class="absolute top-full right-0 mt-2 bg-menu-light dark:bg-menu-dark rounded-lg shadow-lg py-2 min-w-[160px] z-50"
    >
      {#each languageEntries as [code, { flag, name }] (code)}
        <button
          class="w-full px-4 py-2 text-left text-txt-body-light dark:text-txt-body-dark hover:bg-form-light dark:hover:bg-form-dark flex items-center gap-2"
          on:click={() => setLanguage(code)}
        >
          <span class="text-xl leading-none">{flag}</span>
          <span>{name}</span>
        </button>
      {/each}
    </div>
  {/if}
</div>
