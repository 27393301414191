<script lang="ts">
  import { Link } from "svelte-routing";
  import { notFoundTranslations } from "./t_404";
  import { language, type LanguageCode } from "../../../stores/langStore";
</script>

<div id="error_container" class="flex justify-center items-center">
  <div
    id="error_card"
    class="flex flex-col p-xl text-center rounded-xl bg-box-light shadow-lg dark:bg-box-dark"
  >
    <h1
      id="error_code"
      class="text-[6rem] m-0 text-accent-light dark:text-accent-dark"
    >
      {notFoundTranslations[$language].errorCode}
    </h1>
    <h2 id="error_title" class="text-[2rem] m-0 mb-md">
      {notFoundTranslations[$language].title}
    </h2>
    <p id="error_message" class="text-[1.1rem] mb-lg">
      {notFoundTranslations[$language].message}
    </p>
    <Link
      to="/"
      id="home_link"
      class="inline-block px-md py-sm rounded-md bg-accent-light text-text-light dark:bg-accent-dark dark:text-text-dark transition-colors"
    >
      {notFoundTranslations[$language].homeButton}
    </Link>
  </div>
</div>
