import { type LanguageCode } from "../../../stores/langStore";

export type NotFoundTranslation = {
    errorCode: string;
    title: string;
    message: string;
    homeButton: string;
};

export const notFoundTranslations: Record<LanguageCode, NotFoundTranslation> = {
    en: {
        errorCode: "404",
        title: "Page Not Found",
        message: "The page you're looking for doesn't exist or has been moved.",
        homeButton: "Return Home"
    },
    ru: {
        errorCode: "404",
        title: "Страница не найдена",
        message: "Страница, которую вы ищете, не существует или была перемещена.",
        homeButton: "На главную"
    }
};
