<script lang="ts">
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import { authStore } from "../../stores/authStore";
  import { checkAuthStatus } from "../../api/auth/auth";
  export let component: any;

  let isChecking = false;

  onMount(async () => {
    if (isChecking) return;
    isChecking = true;

    try {
      const authStatus = await checkAuthStatus();
      if (!authStatus) {
        authStore.clearUser();
        navigate("/login");
      }
    } catch (error) {
      console.error("Auth check failed:", error);
      authStore.clearUser();
      navigate("/login");
    } finally {
      isChecking = false;
    }
  });
</script>

<svelte:component this={component} />
