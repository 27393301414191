<script lang="ts">
  import { onMount } from "svelte";
  import { darkMode } from "../../stores/darkMode";

  onMount(() => {
    const unsubscribe = darkMode.init();
    return unsubscribe;
  });
</script>

<main
  class:dark={$darkMode}
  class="flex-1 flex flex-col bg-bg-light text-txt-body-light dark:bg-bg-dark dark:text-txt-body-dark"
>
  <div class="h-full flex items-center justify-center">
    <slot></slot>
  </div>
</main>
