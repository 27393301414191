<script lang="ts">
  import Navbar from "./menu/Menu.svelte";
  import Footer from "./footer/Footer.svelte";
  import Content from "./Content.svelte";
</script>

<div class="app h-screen flex flex-col">
  <Navbar />
  <Content>
    <slot></slot>
  </Content>

  <Footer />
</div>

<style>
  .app {
    display: flex;
    flex-direction: column;
  }
</style>
