<script lang="ts">
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import { authStore } from "../../../stores/authStore";
  import { checkAuthStatus } from "../../../api/auth/auth";
  import { logger } from "../../../utils/logger/logger";
  let isLoggedIn = false;

  // Subscribe to changes in the user's login status
  authStore.subscribe((value) => {
    isLoggedIn = value.isLoggedIn;
  });

  onMount(async () => {
    logger.info("Hub mounted");
    try {
      const response = await checkAuthStatus();

      if (response.error) {
        logger.info("Auth check failed, redirecting to /login");
        authStore.clearUser();
        navigate("/login");
        return;
      }

      if (response.data) {
        logger.info("Auth check successful, setting user data");
        authStore.setUser({
          id: response.data.id?.toString() || "",
          username: response.data.username || "",
          email: response.data.email || "",
        });
        navigate("/status");
      } else {
        logger.info("No user data received, redirecting to /login");
        authStore.clearUser();
        navigate("/login");
      }
    } catch (error) {
      logger.error("Error during auth check:", error);
      authStore.clearUser();
      navigate("/login");
    }
  });
</script>

<div>Callback here</div>
