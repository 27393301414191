<script lang="ts">
  import { darkMode } from "../../../stores/darkMode";

  // Update document class when dark mode changes
  $: if (typeof document !== "undefined") {
    document.documentElement.classList.toggle("dark", $darkMode);
  }
</script>

<button
  class="flex items-center justify-center p-1 bg-transparent border-none cursor-pointer text-base hover:opacity-80 transition-opacity duration-normal"
  on:click={() => darkMode.toggle()}
>
  {$darkMode ? "🌙" : "☀️"}
</button>
