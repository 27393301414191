import { type LanguageCode } from "../../../stores/langStore";

export type VerifyTranslation = {
    title: string;
    conclusion: string;
    checkSpam: string;
    backToLogin: string;
    loginFailed: string;
    checkCredentials: string;
};

export const verifyTranslations: Record<LanguageCode, VerifyTranslation> = {
    en: {
        title: "Registration Successful",
        conclusion: "Your registration was successful! Please check your email to verify your account.",
        checkSpam: "If you don't see the verification email, please check your spam folder.",
        backToLogin: "Back to Login",
        loginFailed: "Login failed",
        checkCredentials: "Please check your credentials and try again.",
    },
    ru: {
        title: "Регистрация Успешна",
        conclusion: "Ваша регистрация прошла успешно! Пожалуйста, проверьте вашу почту для верификации аккаунта.",
        checkSpam: "Если вы не видите письмо для верификации, пожалуйста, проверьте папку спам.",
        backToLogin: "Вернуться к входу",
        loginFailed: "Ошибка входа",
        checkCredentials: "Пожалуйста, проверьте ваши учетные данные и попробуйте снова.",
    },
};