<script lang="ts">
  import { onMount } from "svelte";
  import { getApiStatus } from "../../api/api/api";
  import { checkAuthStatus } from "../../api/auth/auth";
  import DataBox from "./StatusDataBox.svelte";
  import { authStore } from "../../stores/authStore";
  import { statusTranslations } from "./t_status";
  import { language } from "../../stores/langStore";
  import { slide } from "svelte/transition";
  let isLoggedIn = false;
  let authResponse = {};
  let apiResponse = {};

  authStore.subscribe((value) => {
    const wasLoggedIn = isLoggedIn;
    isLoggedIn = value.isLoggedIn;
    if (isLoggedIn && !wasLoggedIn) {
      loadData();
    }
  });

  async function loadData() {
    try {
      const [authResp, apiResp] = await Promise.all([checkAuthStatus(), getApiStatus()]);
      authResponse = authResp;
      apiResponse = apiResp;
    } catch (error) {
      console.error("Error loading status data:", error);
    }
  }

  // onMount(() => {});
</script>

{#if isLoggedIn}
  <div class="container mx-auto max-w-3xl px-4 my-8 space-y-4">
    <DataBox
      title={statusTranslations[$language].auth}
      data={{ status: statusTranslations[$language].loggedIn, ...authResponse }}
    />
    <DataBox title={statusTranslations[$language].api} data={apiResponse} />
  </div>
{:else}
  <div class="container mx-auto max-w-3xl px-4 my-8">
    <DataBox
      title={statusTranslations[$language].status}
      data={{
        [statusTranslations[$language].status]:
          statusTranslations[$language].loggedOut,
      }}
    />
  </div>
{/if}
