import { type LanguageCode } from "../../../stores/langStore";

// Define the type for register translations
export type RegisterTranslation = {
    createAccount: string;
    email: string;
    username: string;
    password: string;
    confirmPassword: string;
    register: string;
    signInInstead: string;
    continueWith: string;
    registrationFailed: string;
    tryAgain: string;
    passwordsDoNotMatch: string;
    usernameRequired: string;
    passwordTooShort: string;
    passwordNeedsUppercase: string;
    passwordNeedsLowercase: string;
    passwordNeedsNumber: string;
    passwordNeedsSpecial: string;
};

// Export register translations for each language
export const registerTranslations: Record<LanguageCode, RegisterTranslation> = {
    en: {
        createAccount: "Create Account",
        email: "Email",
        username: "Username",
        password: "Password",
        confirmPassword: "Confirm Password",
        register: "Register",
        signInInstead: "Sign in instead",
        continueWith: "or continue with",
        registrationFailed: "Registration failed",
        tryAgain: "Please try again.",
        passwordsDoNotMatch: "Passwords do not match",
        usernameRequired: "Username is required",
        passwordTooShort: "Password must be at least 8 characters long",
        passwordNeedsUppercase: "Password must contain at least one uppercase letter",
        passwordNeedsLowercase: "Password must contain at least one lowercase letter",
        passwordNeedsNumber: "Password must contain at least one number",
        passwordNeedsSpecial: "Password must contain at least one special character (!@#$%^&*)",
    },
    ru: {
        createAccount: "Создать аккаунт",
        email: "Электронная почта",
        username: "Имя пользователя",
        password: "Пароль",
        confirmPassword: "Подтвердите пароль",
        register: "Зарегистрироваться",
        signInInstead: "Войти",
        continueWith: "или продолжить с помощью",
        registrationFailed: "Ошибка регистрации",
        tryAgain: "Пожалуйста, попробуйте снова.",
        passwordsDoNotMatch: "Пароли не совпадают",
        usernameRequired: "Имя пользователя обязательно",
        passwordTooShort: "Пароль должен быть не менее 8 символов",
        passwordNeedsUppercase: "Пароль должен содержать хотя бы одну заглавную букву",
        passwordNeedsLowercase: "Пароль должен содержать хотя бы одну строчную букву",
        passwordNeedsNumber: "Пароль должен содержать хотя бы одну цифру",
        passwordNeedsSpecial: "Пароль должен содержать хотя бы один специальный символ (!@#$%^&*)",
    },
};
