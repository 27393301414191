import { type LanguageCode } from "../../../stores/langStore";

export type VerifyTranslation = {
    title: string;
    conclusion: string;
    checkSpam: string;
    backToLogin: string;
    loginFailed: string;
    checkCredentials: string;
};

export const verifyTranslations: Record<LanguageCode, VerifyTranslation> = {
    en: {
        title: "Verify",
        conclusion: "Please check your email for verification instructions.",
        checkSpam: "If you don't see the email, please check your spam folder.",
        backToLogin: "Back to Login",
        loginFailed: "Login failed",
        checkCredentials: "Please check your credentials and try again.",
    },
    ru: {
        title: "Верификация",
        conclusion: "Пожалуйста, проверьте ваш email для получения инструкций по верификации.",
        checkSpam: "Если вы не видите письмо, пожалуйста, проверьте папку спам.",
        backToLogin: "Вернуться к входу",
        loginFailed: "Ошибка входа",
        checkCredentials: "Пожалуйста, проверьте ваши учетные данные и попробуйте снова.",
    },
};