<script lang="ts">
    import { onMount } from "svelte";
    import { Link, navigate } from "svelte-routing";
    import GithubLogo from "../../../assets/images/github.svg";
    import { registerTranslations } from "./t_register";
    import { language } from "../../../stores/langStore";
    import { authStore } from "../../../stores/authStore";
    import { logger } from "../../../utils/logger/logger";
    import { registerUser } from "../../../api/auth/auth";
    let email = "";
    let username = "";
    let password = "";
    let confirmPassword = "";
    let errorMessage = "";

    function validatePassword(password: string): string | null {
        if (password.length < 8) {
            return (
                registerTranslations[$language].passwordTooShort ||
                "Password must be at least 8 characters long"
            );
        }
        if (!/[A-Z]/.test(password)) {
            return (
                registerTranslations[$language].passwordNeedsUppercase ||
                "Password must contain at least one uppercase letter"
            );
        }
        if (!/[a-z]/.test(password)) {
            return (
                registerTranslations[$language].passwordNeedsLowercase ||
                "Password must contain at least one lowercase letter"
            );
        }
        if (!/[0-9]/.test(password)) {
            return (
                registerTranslations[$language].passwordNeedsNumber ||
                "Password must contain at least one number"
            );
        }
        if (!/[!@#$%^&*]/.test(password)) {
            return (
                registerTranslations[$language].passwordNeedsSpecial ||
                "Password must contain at least one special character (!@#$%^&*)"
            );
        }
        return null;
    }

    async function handleSubmit() {
        try {
            errorMessage = ""; // Clear any previous errors

            // Validate password
            const passwordError = validatePassword(password);
            if (passwordError) {
                errorMessage = passwordError;
                return;
            }

            if (password !== confirmPassword) {
                throw new Error(
                    registerTranslations[$language].passwordsDoNotMatch,
                );
            }

            const response = await registerUser(email, username, password);
            if (response.error) {
                errorMessage = response.error;
                logger.error("Registration error:", response.errorDetails);
                return;
            }

            // Redirect to success page after successful registration
            navigate("/register/success");
        } catch (error) {
            logger.error("Registration error:", error);
            errorMessage =
                error instanceof Error ? error.message : "Unknown error";
        }
    }

    function registerWithGithub() {
        // Implement GitHub registration logic
        logger.info("Register with GitHub");
    }

    onMount(() => {
        // Any initialization logic can go here
    });
</script>

<div
    id="register-page-container"
    class="flex-1 container flex justify-center items-center mx-auto px-4 py-12"
>
    <div id="register-content-wrapper" class="w-full max-w-[400px] mx-auto">
        <h1
            id="register-heading"
            class="text-4xl text-center tracking-tight font-bold mb-8 text-txt-heading-light dark:text-txt-heading-dark"
        >
            {registerTranslations[$language].createAccount}
        </h1>

        <div
            id="register-form-container"
            class="w-full rounded-xl p-8 bg-box-light dark:bg-box-dark shadow-lg"
        >
            {#if errorMessage}
                <div
                    id="register-error-message"
                    class="p-4 mb-6 bg-status-error/10 rounded-lg text-sm text-status-error font-medium"
                >
                    {errorMessage}
                </div>
            {/if}

            <form
                id="register-form"
                on:submit|preventDefault={handleSubmit}
                class="w-full flex flex-col gap-5"
            >
                <div id="register-email-group" class="flex flex-col">
                    <label
                        for="email"
                        class="mb-1.5 text-sm text-txt-body-light dark:text-txt-body-dark font-medium"
                    >
                        {registerTranslations[$language].email}
                    </label>
                    <input
                        type="email"
                        id="email"
                        bind:value={email}
                        required
                        class="p-2.5 bg-form-input-light dark:bg-form-input-dark border border-form-border-light dark:border-form-border-dark rounded-lg text-txt-body-light dark:text-txt-body-dark transition-all duration-200 focus:ring-2 focus:ring-form-border-focus focus:border-form-border-focus"
                    />
                </div>

                <div id="register-username-group" class="flex flex-col">
                    <label
                        for="username"
                        class="mb-1.5 text-sm text-txt-body-light dark:text-txt-body-dark font-medium"
                    >
                        {registerTranslations[$language].username}
                    </label>
                    <input
                        type="text"
                        id="username"
                        bind:value={username}
                        required
                        class="p-2.5 bg-form-input-light dark:bg-form-input-dark border border-form-border-light dark:border-form-border-dark rounded-lg text-txt-body-light dark:text-txt-body-dark transition-all duration-200 focus:ring-2 focus:ring-form-border-focus focus:border-form-border-focus"
                    />
                </div>

                <div id="register-password-group" class="flex flex-col">
                    <label
                        for="password"
                        class="mb-1.5 text-sm text-txt-body-light dark:text-txt-body-dark font-medium"
                    >
                        {registerTranslations[$language].password}
                    </label>
                    <input
                        type="password"
                        id="password"
                        bind:value={password}
                        required
                        class="p-2.5 bg-form-input-light dark:bg-form-input-dark border border-form-border-light dark:border-form-border-dark rounded-lg text-txt-body-light dark:text-txt-body-dark transition-all duration-200 focus:ring-2 focus:ring-form-border-focus focus:border-form-border-focus"
                    />
                </div>

                <div id="register-confirm-password-group" class="flex flex-col">
                    <label
                        for="confirmPassword"
                        class="mb-1.5 text-sm text-txt-body-light dark:text-txt-body-dark font-medium"
                    >
                        {registerTranslations[$language].confirmPassword}
                    </label>
                    <input
                        type="password"
                        id="confirmPassword"
                        bind:value={confirmPassword}
                        required
                        class="p-2.5 bg-form-input-light dark:bg-form-input-dark border border-form-border-light dark:border-form-border-dark rounded-lg text-txt-body-light dark:text-txt-body-dark transition-all duration-200 focus:ring-2 focus:ring-form-border-focus focus:border-form-border-focus"
                    />
                </div>

                <div
                    id="register-buttons-group"
                    class="flex flex-col gap-3 mt-2"
                >
                    <button
                        type="submit"
                        class="w-full py-2.5 bg-accent-light hover:bg-accent-light-hover active:bg-accent-light-active dark:bg-accent-dark dark:hover:bg-accent-dark-hover dark:active:bg-accent-dark-active rounded-lg text-txt-body-dark font-medium transition-colors duration-200 focus:ring-2 focus:ring-offset-2 focus:ring-accent-light"
                    >
                        {registerTranslations[$language].register}
                    </button>
                    <Link to="/login" class="w-full">
                        <div
                            class="w-full py-2.5 bg-form-input-light dark:bg-form-input-dark hover:bg-form-border-light dark:hover:bg-form-border-dark rounded-lg text-txt-body-light dark:text-txt-body-dark font-medium text-center transition-colors duration-200"
                        >
                            {registerTranslations[$language].signInInstead}
                        </div>
                    </Link>
                </div>
            </form>

            <div id="register-divider" class="flex items-center my-6">
                <div
                    class="flex-1 border-t border-form-border-light dark:border-form-border-dark"
                ></div>
                <span
                    class="px-4 text-sm text-txt-muted-light dark:text-txt-muted-dark font-medium"
                >
                    {registerTranslations[$language].continueWith}
                </span>
                <div
                    class="flex-1 border-t border-form-border-light dark:border-form-border-dark"
                ></div>
            </div>

            <div id="register-social-buttons" class="flex gap-3">
                <button
                    on:click={registerWithGithub}
                    class="flex-1 flex items-center justify-center gap-2 px-4 py-2.5 bg-form-input-light dark:bg-form-input-dark hover:bg-form-border-light dark:hover:bg-form-border-dark rounded-lg text-txt-body-light dark:text-txt-body-dark font-medium transition-colors duration-200"
                >
                    <img src={GithubLogo} alt="GitHub Logo" class="w-5 h-5" />
                    <span>GitHub</span>
                </button>
            </div>
        </div>
    </div>
</div>
