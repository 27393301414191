<script lang="ts">
  import { onMount, onDestroy } from "svelte";
  import L from "leaflet";
  import "leaflet/dist/leaflet.css";
  import "leaflet-draw";
  import "leaflet-draw/dist/leaflet.draw.css";

  let mapElement: HTMLElement;
  let map: L.Map;
  let drawnItems: L.FeatureGroup;

  // Add new variables for tracking measurement
  let userLocation: L.LatLng | null = null;
  let measurementLayer: L.LayerGroup;

  // Function to initialize the map
  function initBerlinMap() {
    map = L.map(mapElement).setView([52.51, 13.405], 13);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);
  }

  // Function to create and add a polygon
  function addStralauPolygon() {
    const stralauCoordinates: L.LatLngExpression[] = [
      [52.498014543640025, 13.467822074890138],
      [52.501763520201465, 13.469603061676027],
      [52.500535667613384, 13.472135066986086],
      [52.49806679638115, 13.475074768066408],
      [52.49498377840183, 13.478980064392092],
      [52.491639243283714, 13.484344482421877],
      [52.48944425387241, 13.487477302551271],
      [52.487615012344676, 13.481898307800295],
      [52.49077693907994, 13.476877212524416],
      [52.49226636299416, 13.472414016723635],
      [52.49328541345049, 13.468208312988283],
      [52.49571536155163, 13.464260101318361],
    ];

    const stralauPolygon = L.polygon(stralauCoordinates, {
      color: "green",
      weight: 2,
      fillColor: "green",
      fillOpacity: 0.2,
    }).addTo(map);

    stralauPolygon.bindPopup("Stralau Peninsula Area");
    drawnItems.addLayer(stralauPolygon);
  }

  // Function to add a marker
  function addStralauMarker() {
    const marker = L.marker([52.49, 13.481], {
      icon: L.divIcon({
        className: "custom-icon",
        html: '<div style="background-color: green; width: 13px; height: 13px; border-radius: 50%;"></div>',
      }),
    })
      .addTo(map)
      .bindPopup(
        "<div style='text-align: center; font-family: Arial, sans-serif; color: #2c3e50;'><strong style='font-size: 1.2em;'>Stralau, Berlin</strong><br><span style='font-size: 1em;'>A picturesque area by the water, perfect for a serene escape.</span><br><img src='https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Berlin_Stralau_UAV_04-2017.jpg/1200px-Berlin_Stralau_UAV_04-2017.jpg' alt='Stralau, Berlin' style='width:100%;height:auto;margin-top:10px;border-radius:8px;'><br><a href='https://en.wikipedia.org/wiki/Stralau' target='_blank' style='display: inline-block; margin-top: 10px; padding: 5px 10px; background-color: #3498db; color: white; text-decoration: none; border-radius: 5px;'>Learn more about Stralau</a></div>",
      );
  }

  // Function to initialize drawing controls
  function initDrawControls() {
    const drawControl = new L.Control.Draw({
      edit: {
        featureGroup: drawnItems,
        poly: {
          allowIntersection: false,
        },
      },
      draw: {
        polygon: {
          allowIntersection: false,
          showArea: true,
        },
        polyline: false,
        circle: true,
        circlemarker: false,
        marker: true,
      },
    });
    map.addControl(drawControl);
  }

  // Function to handle map events
  function handleMapEvents() {
    map.on(L.Draw.Event.CREATED, (event: L.DrawEvents.Created) => {
      const layer = event.layer;
      drawnItems.addLayer(layer);

      if (layer instanceof L.Polygon || layer instanceof L.Rectangle) {
        const coordinates = layer.getLatLngs();
        console.log("Selected area coordinates:", coordinates);
      } else if (layer instanceof L.Circle) {
        const center = layer.getLatLng();
        const radius = layer.getRadius();
        console.log("Selected circle center:", center, "radius:", radius);
      } else if (layer instanceof L.Marker) {
        const position = layer.getLatLng();
        console.log("Marker placed at:", position);
        layer.bindPopup("New marker").openPopup();
      }
    });

    map.on(L.Draw.Event.EDITED, (event: L.DrawEvents.Edited) => {
      const layers = event.layers;
      layers.eachLayer((layer: L.Layer) => {
        if (layer instanceof L.Polygon || layer instanceof L.Rectangle) {
          const coordinates = layer.getLatLngs();
          console.log("Edited area coordinates:", coordinates);
        }
      });
    });
  }

  // Function to initialize the map and its components
  function setupMap() {
    initBerlinMap();
    drawnItems = new L.FeatureGroup();
    map.addLayer(drawnItems);
    addStralauPolygon();
    addStralauMarker();
    initDrawControls();
    handleMapEvents();
    setTimeout(() => map.invalidateSize(), 100);
  }

  onMount(() => {
    setTimeout(setupMap, 0);
  });

  onDestroy(() => {
    if (map) {
      map.remove();
    }
  });

  function changeView(lat: number, lng: number, zoom: number) {
    if (map) {
      map.setView([lat, lng], zoom);
    }
  }

  // Modify the locateUser function to store user location
  function locateUser() {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        userLocation = L.latLng(latitude, longitude);
        map.setView([latitude, longitude], 16);

        // Clear previous measurement layers
        if (measurementLayer) {
          measurementLayer.clearLayers();
        } else {
          measurementLayer = L.layerGroup().addTo(map);
        }

        // Add user marker
        const userMarker = L.marker(userLocation, {
          icon: L.divIcon({
            className: "custom-icon",
            html: '<div style="background-color: #2196F3; width: 15px; height: 15px; border-radius: 50%; border: 2px solid white;"></div>',
          }),
        });
        measurementLayer.addLayer(userMarker);
        userMarker.bindPopup("You are here!").openPopup();

        // Enable click measurement after getting location
        enableDistanceMeasurement();
      },
      (error) => {
        alert(`Error getting location: ${error.message}`);
      },
    );
  }

  // Function to enable distance measurement
  function enableDistanceMeasurement() {
    if (!userLocation) {
      alert("Please enable location first!");
      return;
    }

    map.on("click", (e) => {
      const clickedPoint = e.latlng;
      const distance = userLocation.distanceTo(clickedPoint);

      // Clear previous measurement layers except user marker
      measurementLayer.clearLayers();

      // Add user marker again
      const userMarker = L.marker(userLocation, {
        icon: L.divIcon({
          className: "custom-icon",
          html: '<div style="background-color: #2196F3; width: 15px; height: 15px; border-radius: 50%; border: 2px solid white;"></div>',
        }),
      });
      measurementLayer.addLayer(userMarker);

      // Add clicked point marker
      const pointMarker = L.marker(clickedPoint).addTo(measurementLayer);

      // Draw line between points
      const line = L.polyline([userLocation, clickedPoint], {
        color: "#FF4444",
        weight: 3,
        dashArray: "5, 10",
      }).addTo(measurementLayer);

      // Format distance
      const distanceKm = (distance / 1000).toFixed(2);
      const distanceM = Math.round(distance);

      // Add popup with distance
      pointMarker
        .bindPopup(`Distance: ${distanceKm} km (${distanceM} m)`)
        .openPopup();
    });
  }
</script>

<div class="map-container">
  <div class="sidebar">
    <h3>Cities</h3>
    <button class="locate-btn" on:click={locateUser}> 📍 My Location </button>
    <div class="info-text">
      Click anywhere on map to measure distance from your location
    </div>
    <button on:click={() => changeView(48.8566, 2.3522, 13)}>Paris</button>
    <button on:click={() => changeView(51.5074, -0.1278, 13)}>London</button>
    <button on:click={() => changeView(52.51, 13.405, 13)}>Berlin</button>
    <button on:click={() => changeView(40.7128, -74.006, 12)}>New York</button>
    <button on:click={() => changeView(35.6762, 139.6503, 12)}>Tokyo</button>
  </div>
  <div id="map" bind:this={mapElement}></div>
</div>

<style>
  .map-container {
    display: flex;
    height: 100vh;
    width: 100%;
    position: relative;
  }

  .sidebar {
    width: 120px;
    background-color: #4c4c4c;
    padding: 15px 10px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .sidebar h3 {
    margin: 0 0 15px 0;
    text-align: center;
    color: #333;
    font-size: 1.2em;
  }

  .sidebar button {
    padding: 10px;
    border: none;
    background-color: #3498db;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.2s;
  }

  .sidebar button:hover {
    background-color: #2980b9;
  }

  #map {
    flex: 1;
    z-index: 1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
    height: 100%;
  }

  :global(.leaflet-container) {
    height: 100%;
    width: 100%;
  }

  :global(.leaflet-popup-content-wrapper) {
    border-radius: 8px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.2);
  }

  :global(.leaflet-popup-content) {
    margin: 13px 19px;
    line-height: 1.4;
  }

  .locate-btn {
    background-color: #2196f3 !important;
    margin-bottom: 15px;
  }

  .locate-btn:hover {
    background-color: #1976d2 !important;
  }

  .info-text {
    color: #ffffff;
    font-size: 0.8em;
    text-align: center;
    margin-bottom: 15px;
    padding: 0 5px;
  }
</style>
