<script lang="ts">
    import { Link } from "svelte-routing";
    import { authStore } from "../../../stores/authStore";
    import { verifyTranslations } from "./t_verify";
    import { language, type LanguageCode } from "../../../stores/langStore";
    import { loginUser } from "../../../api/auth/auth";
    let email = "";
    let password = "";
    let errorMessage = "";

    async function handleSubmit() {
        try {
            await loginUser(email, password);
        } catch (error) {
            errorMessage = `${verifyTranslations[$language].loginFailed}: ${(error as Error).message}. ${verifyTranslations[$language].checkCredentials}`;
        }
    }

    function loginWithGithub() {
        // Redirect to the backend endpoint that initiates the GitHub OAuth flow
        window.location.href = import.meta.env.PROD
            ? "https://auth.shindo.dev/github/login"
            : "/auth/github/login";
    }
</script>

<div
    id="verifyContainer"
    class="
        flex-1
        container
        flex
        justify-center
        items-center
        mx-auto
        px-4
        py-12
    "
>
    <div
        id="verifyWrapper"
        class="
            w-full
            max-w-[400px]
            mx-auto
        "
    >
        <h1
            id="verifyTitle"
            class="
                text-4xl
                text-center
                text-txt-heading-light dark:text-txt-heading-dark
                tracking-tight
                font-bold
                mb-8
            "
        >
            {verifyTranslations[$language].title}
        </h1>

        <div
            id="verifyCard"
            class="
                w-full
                p-8
                bg-box-light dark:bg-box-dark
                rounded-xl
                shadow-lg
                text-center
            "
        >
            <p
                class="
                text-txt-body-light dark:text-txt-body-dark
                mb-6
            "
            >
                {verifyTranslations[$language].conclusion}
            </p>

            <p
                class="
                text-sm
                text-txt-muted-light dark:text-txt-muted-dark
                mb-8
            "
            >
                {verifyTranslations[$language].checkSpam}
            </p>

            <Link
                to="/login"
                class="
                    inline-block
                    w-full
                    py-2.5
                    bg-accent-light
                    hover:bg-accent-light-hover
                    active:bg-accent-light-active
                    dark:bg-accent-dark
                    dark:hover:bg-accent-dark-hover
                    dark:active:bg-accent-dark-active
                    rounded-lg
                    text-txt-body-dark
                    font-medium
                    transition-colors
                    duration-200
                "
            >
                {verifyTranslations[$language].backToLogin}
            </Link>
        </div>
    </div>
</div>
