import type { LanguageCode } from '../../stores/langStore';

interface AuthTranslations {
  emailExists: string;
  usernameExists: string;
  invalidInput: string;
  registrationFailed: string;
  networkError: string;
  unknownError: string;
  loginFailed: string;
  invalidCredentials: string;
  accountNotVerified: string;
  verificationRequired: string;
  verificationSuccess: string;
  verificationFailed: string;
}

type TranslationsMap = {
  [key in LanguageCode]: AuthTranslations;
};

export const authTranslations: TranslationsMap = {
  en: {
    emailExists: 'This email is already registered',
    usernameExists: 'This username is already taken',
    invalidInput: 'Invalid input data',
    registrationFailed: 'Registration failed',
    networkError: 'Network error occurred',
    unknownError: 'An unknown error occurred',
    loginFailed: 'Login failed',
    invalidCredentials: 'Invalid email or password',
    accountNotVerified: 'Account not verified',
    verificationRequired: 'Please verify your email address',
    verificationSuccess: 'Email verification successful',
    verificationFailed: 'Email verification failed'
  },
  ru: {
    emailExists: 'Этот email уже зарегистрирован',
    usernameExists: 'Этот username уже занят',
    invalidInput: 'Неверные входные данные',
    registrationFailed: 'Регистрация не удалась',
    networkError: 'Произошла сетевая ошибка',
    unknownError: 'Произошла неизвестная ошибка',
    loginFailed: 'Вход не выполнен',
    invalidCredentials: 'Неверный email или пароль',
    accountNotVerified: 'Аккаунт не подтвержден',
    verificationRequired: 'Пожалуйста, подтвердите свой email',
    verificationSuccess: 'Email подтвержден успешно',
    verificationFailed: 'Email подтверждение не удалось'
  }
};
