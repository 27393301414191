import { writable } from 'svelte/store'
import { logger } from '../utils/logger/logger'

// Language code type
export type LanguageCode = 'en' | 'ru'

// Language configuration type
export interface Language {
  flag: string
  name: string
}

// Language configurations
export const languages: Record<LanguageCode, Language> = {
  en: { flag: "🇺🇸", name: "English" },
  ru: { flag: "🇷🇺", name: "Русский" },
}

function createLanguageStore() {
  // Initialize with value from localStorage, fallback to 'en'
  logger.info('Initializing language store')
  const { subscribe, set } = writable<LanguageCode>(
    (localStorage.getItem('language') as LanguageCode) || 'en'
  )

  return {
    subscribe,
    set: (value: LanguageCode) => {
      logger.info(`Setting language to ${value}`)
      localStorage.setItem('language', value)
      document.documentElement.setAttribute('lang', value)
      set(value)
    },
    init: () => {
      // Check if user has a stored preference
      const storedLanguage = localStorage.getItem('language')

      if (storedLanguage === null) {
        // If no stored preference, use browser language
        const browserLang = navigator.language.split('-')[0] as LanguageCode
        const supportedLang = ['en', 'ru'].includes(browserLang) ? browserLang : 'en'
        set(supportedLang)
        localStorage.setItem('language', supportedLang)
        document.documentElement.setAttribute('lang', supportedLang)
      }
    }
  }
}

export const language = createLanguageStore()
