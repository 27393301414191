import { type LanguageCode } from "../../../stores/langStore";

export type ContactTranslation = {
    title: string;
    email: string;
    name: string;
    message: string;
    send: string;
    namePlaceholder: string;
    messagePlaceholder: string;
    successMessage: string;
    errorMessage: string;
};

export const contactTranslations: Record<LanguageCode, ContactTranslation> = {
    en: {
        title: "Contact Us",
        email: "Email: hello@shindo.dev",
        name: "Your Name",
        message: "Message",
        send: "Send Message",
        namePlaceholder: "Enter your name",
        messagePlaceholder: "Type your message here...",
        successMessage: "Message sent successfully!",
        errorMessage: "Failed to send message. Please try again."
    },
    ru: {
        title: "Связаться с нами",
        email: "Email: hello@shindo.dev",
        name: "Ваше имя",
        message: "Сообщение",
        send: "Отправить сообщение",
        namePlaceholder: "Введите ваше имя",
        messagePlaceholder: "Введите ваше сообщение здесь...",
        successMessage: "Сообщение успешно отправлено!",
        errorMessage: "Не удалось отправить сообщение. Пожалуйста, попробуйте еще раз."
    },
};