<script lang="ts">
  export let title: string | undefined = undefined;
</script>

<section class="bg-white dark:bg-gray-700
  /* Improved rounded corners using golden ratio principles */
  rounded-[1rem]
  /* Sophisticated shadow system with layered effects */
  shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1),0_2px_4px_-2px_rgba(0,0,0,0.05)]
  hover:shadow-[0_10px_15px_-3px_rgba(0,0,0,0.1),0_4px_6px_-4px_rgba(0,0,0,0.05)]
  transition-shadow duration-300
  /* Padding based on golden ratio (1.618) multiplier */
  p-8 sm:p-[1.618rem]
  /* Rest of the existing classes */
  text-base leading-relaxed antialiased prose prose-lg
  prose-p:text-justify prose-p:hyphens-auto
  prose-p:word-spacing-[0.01em] prose-p:mb-6 prose-p:tracking-normal
  prose-p:leading-relaxed prose-quoteless
  dark:prose-invert mb-12
  max-w-readable overflow-hidden">
  {#if title}
    <h2 class="text-xl sm:text-2xl font-semibold
      /* Title margin using golden ratio */
      mb-[1.618rem]
      leading-snug">
      {title}
    </h2>
  {/if}
  <slot />
</section>