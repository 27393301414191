import { writable } from 'svelte/store';
import { logger } from '../utils/logger/logger';

// Defines the structure for user information
interface UserInfo {
  id: string;
  username: string;
  email: string;
}

// Represents the complete state of the auth store
interface AuthState {
  userInfo: UserInfo | null;
  isLoggedIn: boolean;
  isLoading: boolean;
}

const createAuthStore = () => {
  // Load initial state from localStorage
  const savedAuth = localStorage.getItem('auth');
  const initialState: AuthState = savedAuth
    ? JSON.parse(savedAuth)
    : {
      userInfo: null,
      isLoggedIn: false,
      isLoading: false
    };

  const { subscribe, set, update } = writable<AuthState>(initialState);

  // Helper to save state to localStorage
  const saveToStorage = (state: AuthState) => {
    localStorage.setItem('auth', JSON.stringify(state));
  };

  return {
    subscribe,

    /**
     * Updates store with user data
     */
    setUser: (userData: UserInfo) => {
      update(state => {
        const newState = {
          ...state,
          userInfo: userData,
          isLoggedIn: true,
          isLoading: false
        };
        saveToStorage(newState);
        return newState;
      });
    },

    /**
     * Clears user data from store
     */
    clearUser: () => {
      localStorage.removeItem('auth');
      set({ userInfo: null, isLoggedIn: false, isLoading: false });
    },

    /**
     * Sets loading state
     */
    setLoading: (isLoading: boolean) => {
      update(state => {
        const newState = { ...state, isLoading };
        saveToStorage(newState);
        return newState;
      });
    }
  };
};

export const authStore = createAuthStore();