<script lang="ts">
  import { messages } from "../../stores/dialogStore";
  import { generateAIResponse } from "../../api/api/api";
  import { chatTranslations } from "./t_chat";
  import { language, type LanguageCode } from "../../stores/langStore";

  export let isLoading: boolean;

  let userInput: string = "";

  async function handleSubmit() {
      if (!userInput.trim()) return;

      messages.update((msgs) => [
          ...msgs,
          {
              id: crypto.randomUUID(),
              role: "user",
              content: userInput
          },
      ]);

      userInput = "";
      isLoading = true;

      try {
          const response = await generateAIResponse($messages);

          messages.update((msgs) => [
              ...msgs,
              {
                  id: crypto.randomUUID(),
                  role: "assistant",
                  content: response.data.generated_text
              },
          ]);
      } catch (error) {
          console.error("Error:", error);
          messages.update((msgs) => [
              ...msgs,
              {
                  id: crypto.randomUUID(),
                  role: "assistant",
                  content: chatTranslations[$language].errorMessage,
              },
          ]);
      } finally {
          isLoading = false;
      }
  }

  function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Enter" && !event.shiftKey) {
          event.preventDefault();
          handleSubmit();
      }
  }

  function clearMessages() {
      messages.set([]);
  }
</script>

<div class="sticky bottom-0 bg-form-light dark:bg-form-dark p-md w-full">
  <div class="flex flex-row w-full max-w-2xl mx-auto space-x-md">
      <textarea
          class="flex-grow p-md rounded-lg
              bg-form-input-light dark:bg-form-input-dark
              border-2 border-form-border-dark dark:border-form-border-light
              focus:border-form-border-focus focus:ring-1 focus:ring-form-border-focus
              text-txt-body-light dark:text-txt-body-dark
              placeholder-form-placeholder-dark dark:placeholder-form-placeholder-light
              resize-none"
          id="chat-input"
          bind:value={userInput}
          placeholder={chatTranslations[$language].typeMessage}
          rows="1"
          on:keydown={handleKeyDown}
      ></textarea>

      <div class="flex flex-col space-y-sm">
          <button
              class="px-md py-sm rounded-lg
                  bg-accent-light hover:bg-accent-light-hover active:bg-accent-light-active
                  dark:bg-accent-dark dark:hover:bg-accent-dark-hover dark:active:bg-accent-dark-active
                  text-txt-body-dark
                  focus:outline-none focus:ring-2 focus:ring-form-border-focus"
              on:click={handleSubmit}
          >
              {chatTranslations[$language].submit}
          </button>

          <button
              class="px-md py-sm rounded-lg
                  bg-status-error hover:bg-status-error-hover
                  text-txt-body-dark
                  focus:outline-none focus:ring-2 focus:ring-form-border-focus"
              on:click={clearMessages}
          >
              {chatTranslations[$language].clear}
          </button>
      </div>
  </div>
</div>