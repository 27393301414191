import { type LanguageCode } from "../../../stores/langStore";

export type PrivacyTranslation = {
    title: string;
    introduction: string;
    noData: string;
    contact: string;
};

export const privacyTranslations: Record<LanguageCode, PrivacyTranslation> = {
    en: {
        title: "Privacy Policy",
        introduction: "We value your privacy and want to be transparent about our practices.",
        noData: "While our application does use servers to provide services, we do not profile users or sell any personal data. We only collect the minimum necessary information to provide our services, and we never share your data with third parties for commercial purposes.",
        contact: "If you have any questions about this Privacy Policy, please contact us."
    },
    ru: {
        title: "Политика конфиденциальности",
        introduction: "Мы ценим вашу конфиденциальность и хотим быть прозрачными в отношении наших практик.",
        noData: "Хотя наше приложение использует серверы для предоставления услуг, мы не профилируем пользователей и не продаем личные данные. Мы собираем только минимально необходимую информацию для предоставления наших услуг и никогда не передаем ваши данные третьим лицам в коммерческих целях.",
        contact: "Если у вас есть вопросы по поводу этой Политики конфиденциальности, пожалуйста, свяжитесь с нами."
    },
};
