<script lang="ts">
  import { slide } from "svelte/transition";

  export let title: string;
  export let data: Record<string, any> = {};
  let isExpanded = true;

  // Helper function to format value display
  const formatValue = (value: any) => {
    if (typeof value === "string" && value.length > 50) {
      return {
        type: "long-text",
        content: value,
      };
    } else if (typeof value === "object") {
      return {
        type: "object",
        content: JSON.stringify(value, null, 2),
      };
    }
    return {
      type: "simple",
      content: value,
    };
  };

  function toggleExpand() {
    isExpanded = !isExpanded;
  }
</script>

<div
  class="bg-box-light dark:bg-box-dark rounded-xl shadow-lg p-4 overflow-hidden w-full"
>
  <button
    class="w-full flex items-center justify-between cursor-pointer hover:opacity-80"
    on:click={toggleExpand}
    aria-expanded={isExpanded}
  >
    <h2
      class="text-lg font-bold text-txt-heading-light dark:text-txt-heading-dark tracking-tight"
    >
      {title}
    </h2>
    <svg
      class="w-5 h-5 transform transition-transform duration-200 {isExpanded
        ? 'rotate-180'
        : ''} text-txt-body-light dark:text-txt-body-dark"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M19 9l-7 7-7-7"
      />
    </svg>
  </button>

  {#if isExpanded}
    <div transition:slide={{ duration: 300 }} class="mt-4">
      <div
        class="border-form-border-light dark:border-form-border-dark divide-y divide-form-border-light dark:divide-form-border-dark"
      >
        {#each Object.entries(data) as [key, value]}
          {@const formattedValue = formatValue(value)}
          <div class="py-3 first:pt-2 last:pb-2">
            <h3
              class="font-medium text-xs text-txt-body-light dark:text-txt-body-dark mb-2 uppercase tracking-wide"
            >
              {key}
            </h3>

            {#if formattedValue.type === "long-text" || formattedValue.type === "object"}
              <div class="overflow-x-auto">
                <pre
                  class="text-sm bg-box-light dark:bg-box-dark p-3 rounded-md text-txt-body-light dark:text-txt-body-dark whitespace-pre-wrap break-words font-mono leading-snug">{formattedValue.content}</pre>
              </div>
            {:else}
              <p
                class="text-sm text-txt-body-light dark:text-txt-body-dark break-words leading-snug"
              >
                {formattedValue.content}
              </p>
            {/if}
          </div>
        {/each}
      </div>
    </div>
  {/if}
</div>
