import { type LanguageCode } from "../../stores/langStore";

export type StatusTranslation = {
    auth: string;
    api: string;
    system: string;
    loggedIn: string;
    loggedOut: string;
    status: string;
};

export const statusTranslations: Record<LanguageCode, StatusTranslation> = {
    en: {
        auth: "Auth",
        api: "API",
        system: "System",
        loggedIn: "Logged in",
        loggedOut: "Logged out",
        status: "Status"
    },
    ru: {
        auth: "Авторизация",
        api: "API",
        system: "Система",
        loggedIn: "Вы вошли",
        loggedOut: "Вы вышли",
        status: "Статус"
    },
};
