<script lang="ts">
  import Prism from "prismjs";
  import "prismjs/themes/prism-okaidia.css";
  import "prismjs/plugins/line-numbers/prism-line-numbers.css";
  import "prismjs/plugins/line-numbers/prism-line-numbers";
  import "prismjs/components/prism-go";

  export let filename: string;
  export let code: string;

  // Function to copy code to clipboard
  function copyCode(event: MouseEvent) {
    const button = event.target as HTMLButtonElement;
    const pre = button.closest(".code-section")?.querySelector("pre");
    const code = pre?.querySelector("code");

    if (code) {
      const text = code.innerText;
      navigator.clipboard
        .writeText(text)
        .then(() => {
          button.textContent = "Copied!";
          setTimeout(() => {
            button.textContent = "Copy";
          }, 2000);
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    }
  }
</script>

<div class="code-section mb-4 relative">
  <div class="code-header">
    <span class="filename">{filename}</span>
    <button class="copy-button" on:click={copyCode}>Copy</button>
  </div>
  <pre class="line-numbers"><code class="language-go">{code}</code></pre>
</div>

<style>
  /* Move all the code-related styles here */
  .code-section {
    border-radius: 0.5rem;
    overflow: hidden;
    margin: 0.75rem 0;
    background: #282c34;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }

  .code-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem 1rem;
    background: #21252b;
    border-bottom: 1px solid #181a1f;
  }

  .filename {
    color: #9da5b4;
    font-size: 0.875rem;
    font-family: ui-monospace, 'Menlo', monospace;
  }

  .copy-button {
    background: #3a404b;
    color: #9da5b4;
    padding: 0.15rem 0.5rem;
    border-radius: 0.375rem;
    font-size: 0.75rem;
    transition: all 0.2s;
    border: 1px solid #181a1f;
  }

  .copy-button:hover {
    background: #444b58;
    color: #d7dae0;
  }

  .code-section pre {
    margin: 0;
    padding: 0.4rem 0;
    background: #282c34 !important;
    font-size: 0.875rem;
    line-height: 1.8;
    white-space: pre-wrap;
    word-break: break-all;
    overflow: visible;
  }

  .code-section code {
    font-family: ui-monospace, 'JetBrains Mono', 'Fira Code', monospace;
    color: #abb2bf;
    display: inline-block;
    width: 100%;
  }

  /* Syntax highlighting colors for Atom One Dark Pro */
  :global(.token.comment),
  :global(.token.prolog),
  :global(.token.doctype),
  :global(.token.cdata) {
    color: #7f848e;
    font-style: italic;
  }

  :global(.token.function) {
    color: #61afef;
  }

  :global(.token.keyword) {
    color: #d55fde;
  }

  :global(.token.string) {
    color: #89ca78;
  }

  :global(.token.number) {
    color: #ef596f;
  }

  :global(.token.boolean) {
    color: #d55fde;
  }

  :global(.token.constant) {
    color: #ef596f;
  }

  :global(.token.variable) {
    color: #ef596f;
  }

  :global(.token.operator) {
    color: #56b6c2;
  }

  :global(.token.punctuation) {
    color: #abb2bf;
  }

  /* Line numbers styling */
  :global(.line-numbers .line-numbers-rows) {
    border-right: 1px solid #3b4048 !important;
    padding: 0.75rem 0;
    left: 1rem !important;
    width: 2.5em !important;
    margin-right: 1rem;
  }

  :global(.line-numbers-rows > span:before) {
    color: #495162 !important;
    padding-right: 1em;
  }

  :global(.line-numbers) {
    padding: 0 !important;
    margin: 0 !important;
  }

  :global(.line-numbers code) {
    padding: 0.75rem 1.5em 0.75rem 4.5em !important;
    margin-left: -0.5rem;
  }
</style>