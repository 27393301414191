import { type LanguageCode } from "../../../stores/langStore";

// Define the type for menu translations
export type MenuTranslation = {
    dialog: string;
    status: string;
    login: string;
    logout: string;
    countries: string;
    cities: string;
    shindo: string;
};

// Export menu translations for each language
export const menuTranslations: Record<LanguageCode, MenuTranslation> = {
    en: {
        dialog: "Dialog",
        status: "Status",
        login: "Get In",
        logout: "Get Out",
        countries: "Countries",
        cities: "Cities",
        shindo: "Shindo"
    },
    ru: {
        dialog: "Диалог",
        status: "Статус",
        login: "Войти",
        logout: "Выйти",
        countries: "Страны",
        cities: "Города",
        shindo: "Синдо"
    },
};