import { type LanguageCode } from "../../../stores/langStore";

// Define the type for footer translations
export interface FooterTranslation {
    copyright: string;
    terms: string;
    privacy: string;
    contact: string;
    about: string;
    followUs: string;
    menu: string;
}

// Create translations for all supported languages
export const footerTranslations: Record<LanguageCode, FooterTranslation> = {
    en: {
        copyright: "© 2025 Shindo",
        terms: "Terms of Service",
        privacy: "Privacy Policy",
        contact: "Contact",
        about: "About the Project",
        followUs: "Follow Us",
        menu: "Navigation"
    },
    ru: {
        copyright: "© 2025 Shindo",
        terms: "Условия использования",
        privacy: "Политика конфиденциальности",
        contact: "Контакт",
        about: "О проекте",
        followUs: "Подписывайтесь",
        menu: "Навигация"
    },
};
